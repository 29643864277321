import styled from '@emotion/styled'
import { BannerGallery } from 'app/components/BannerGallery'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  images?: ImageProps[]
  title?: string
}

export const Item = memo(function Item({
  cta,
  description,
  images,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper row space="between" wrap>
          <LeftSide>
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>

            {cta ? (
              <Media greaterThanOrEqual="desktopSmall">
                <FadeInUp>
                  <CTA variant="simple" {...cta} />
                </FadeInUp>
              </Media>
            ) : null}
          </LeftSide>

          <RightSide>
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}

            {cta ? (
              <Media lessThan="desktopSmall">
                <FadeInUp>
                  <CTA variant="simple" {...cta} />
                </FadeInUp>
              </Media>
            ) : null}
          </RightSide>
        </Wrapper>

        {images && images.length > 0 ? (
          <BannerGallery images={images} variant="simple" />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 9.375rem;
  &:first-of-type {
    margin-top: 12.1875rem;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.25rem;
    &:first-of-type {
      margin-top: 6.25rem;
    }

    > div {
      width: 100%;
    }
  }
`

const Wrapper = styled(FlexBox)`
  width: calc(100% - 6.111vw);
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 5.5rem 6.111vw 4.375rem;
  &:before {
    content: '';
    width: 100%;
    height: 40vh;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
    position: absolute;
    top: 100%;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    order: 2;
    padding: 2.5rem 1.5rem;
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: 12.5rem;

  @media (max-width: 1199px) {
    width: 100%;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`

const RightSide = styled.div`
  flex: 1;
  margin-left: 7.639vw;

  @media (max-width: 1199px) {
    margin-left: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`
