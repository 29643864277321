import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const Visual = memo(function Visual({ items }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {uniqBy(items, 'title').map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
`
